<template>

    <section class=" ">

      <v-container fluid>
        <v-row class="align-center sectionPadding">
        
              <v-col cols="12" class="pb-0 pt-0" data-aos="flip-down"  data-aos-easing="ease-in"

    data-aos-delay="150"
    data-aos-duration="1000">
                       <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" >{{ $t('about-txt')}} </div>
            </v-col>
            <v-col cols="12" class="" data-aos="flip-down"  data-aos-easing="ease-in"

    data-aos-delay="250"
    data-aos-duration="1000">
                       <div>{{ $t('about-subtxt')}}</div>
            </v-col>
        </v-row>
           <v-row class="align-center sectionPadding" data-aos="slide-up"  data-aos-easing="ease-in"

    data-aos-delay="150"
    data-aos-duration="1000">
      
             <v-col  cols="12" class="d-sm-flex d-none justify-center conofLineImg pos-relative">
          <img class="about-img"  src="@/assets/about02.png" alt="img" />
          <v-row class="grey-circle rounded-pill">
       
              <img class="ico-img01" src="@/assets/ico.png" alt="img" />
         
            <span class="circle-txt"> zone #1
            </span>
            <p class="circle-subtxt" v-bind:class="{'right-txt':($i18n.locale === 'ar')}">
                          {{ $t('circle-txt1')}}

               </p>
          </v-row>
           <v-row class="grey-circle2 rounded-pill">
            <img class="ico-img01" src="@/assets/ico.png" alt="img" />
         <span class="circle-txt"> zone #2
            </span>
            <p class="circle-subtxt" v-bind:class="{'right-txt':($i18n.locale === 'ar')}">
                                        {{ $t('circle-txt2')}}

               </p>
          </v-row>
            <v-row class="grey-circle3 rounded-pill">
            <img class="ico-img01" src="@/assets/ico.png" alt="img" />
         <span class="circle-txt"> zone #3
            </span>
            <p class="circle-subtxt" v-bind:class="{'right-txt':($i18n.locale === 'ar')}">
                                        {{ $t('circle-txt3')}}

               </p>
          </v-row>
            <v-row class="grey-circle4 rounded-pill">
            <img class="ico-img01" src="@/assets/ico.png" alt="img" />
         <span class="circle-txt"> zone #4
            </span>
            <p class="circle-subtxt" v-bind:class="{'right-txt':($i18n.locale === 'ar')}">
                                        {{ $t('circle-txt4')}}

               </p>
          </v-row>
            <v-row class="grey-circle5 rounded-pill">
            <img class="ico-img01" src="@/assets/ico.png" alt="img" />
         <span class="circle-txt"> zone #5
            </span>
            <p class="circle-subtxt" v-bind:class="{'right-txt':($i18n.locale === 'ar')}">
                                        {{ $t('circle-txt5')}}

               </p>
          </v-row>
            <v-row class="grey-circle6 rounded-pill">
            <img class="ico-img01" src="@/assets/ico.png" alt="img" />
         <span class="circle-txt"> zone #6
            </span>
            <p class="circle-subtxt" v-bind:class="{'right-txt':($i18n.locale === 'ar')}">
                                        {{ $t('circle-txt6')}}

               </p>
          </v-row>
          </v-col>
              <v-col  cols="12"  class=" d-sm-none d-flex TxtAr justify-center conofLineImg  pos-relative">
          <img class="left-img" src="@/assets/img2.png" alt="img" />
          <v-row class="grey-circle TxtAr  rounded-pill">
       
              <img class="ico-img01" src="@/assets/ico.png" alt="img" />
         
            <span class="circle-txt"> zone #1
            </span>
            <p class="circle-subtxt" v-bind:class="{'right-txt':($i18n.locale === 'ar')}">
                          {{ $t('circle-txt1')}}

               </p>
          </v-row>
           <v-row class="grey-circle2 TxtAr rounded-pill">
            <img class="ico-img01" src="@/assets/ico.png" alt="img" />
         <span class="circle-txt"> zone #2
            </span>
            <p class="circle-subtxt" v-bind:class="{'right-txt':($i18n.locale === 'ar')}">
                                        {{ $t('circle-txt2')}}

               </p>
          </v-row>
            <v-row class="grey-circle3 TxtAr rounded-pill">
            <img class="ico-img01" src="@/assets/ico.png" alt="img" />
         <span class="circle-txt"> zone #3
            </span>
            <p class="circle-subtxt" v-bind:class="{'right-txt':($i18n.locale === 'ar')}">
                                        {{ $t('circle-txt3')}}

               </p>
          </v-row>
            <v-row class="grey-circle4 TxtAr rounded-pill">
            <img class="ico-img01" src="@/assets/ico.png" alt="img" />
         <span class="circle-txt"> zone #4
            </span>
            <p class="circle-subtxt" v-bind:class="{'right-txt':($i18n.locale === 'ar')}">
                                        {{ $t('circle-txt4')}}

               </p>
          </v-row>
            <v-row class="grey-circle5 TxtAr rounded-pill">
            <img class="ico-img01" src="@/assets/ico.png" alt="img" />
         <span class="circle-txt"> zone #5
            </span>
            <p class="circle-subtxt" v-bind:class="{'right-txt':($i18n.locale === 'ar')}">
                                        {{ $t('circle-txt5')}}

               </p>
          </v-row>
            <v-row class="grey-circle6 TxtAr rounded-pill">
            <img class="ico-img01" src="@/assets/ico.png" alt="img" />
         <span class="circle-txt"> zone #6
            </span>
            <p class="circle-subtxt" v-bind:class="{'right-txt':($i18n.locale === 'ar')}">
                                        {{ $t('circle-txt6')}}

               </p>
          </v-row>
          </v-col>
            <v-col cols="12" class="">
                       <div>{{ $t('about-txt2')}}</div>
            </v-col>
              <v-col cols="12" class="">
                       <div>{{ $t('about-txt3')}}</div>
            </v-col>
                <v-col cols="12" class="">
                       <div>{{ $t('about-txt4')}}</div>
            </v-col>
        </v-row>
        <section class="sectionPattern2">
              <div class="pattern"> </div>
        <v-row class="align-center sectionPadding">
        <v-col md="5" cols="12" data-aos="slide-right"  data-aos-easing="ease-in"

    data-aos-delay="250"
    data-aos-duration="1000">
                    <img class="about-img"  src="@/assets/about1.png" alt="img" />

        </v-col>
          <v-col md="7" cols="12" data-aos="slide-left"  data-aos-easing="ease-in"

    data-aos-delay="250"
    data-aos-duration="1000">
          <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" > {{ $t('Retail')}}</div>
          <ul class="list-style px-2">
          <li>
            <img class="list-icon"  src="@/assets/list-icon.svg" alt="img" /> {{ $t('Retail1')}}
          </li>
           <li>
              <img class="list-icon"  src="@/assets/list-icon.svg" alt="img" /> {{ $t('Retail2')}}
            
          </li>
           <li>
             <img class="list-icon"  src="@/assets/list-icon.svg" alt="img" /> {{ $t('Retail3')}}
          
           
          </li>
           <li>
             <img class="list-icon"  src="@/assets/list-icon.svg" alt="img" /> {{ $t('Retail4')}}
          
          </li>
          </ul>

        </v-col>
        </v-row>
      
         <v-row class="align-center pink-bg sectionPadding  row-2" >
        <v-col md="5" cols="12"     data-aos="slide-left"  data-aos-easing="ease-in"
           
    data-aos-delay="250"
    data-aos-duration="1000">
                    <img class="about-img"  src="@/assets/about2.png" alt="img" />

        </v-col>
          <v-col md="7" cols="12" data-aos="slide-right"  data-aos-easing="ease-in"

    data-aos-delay="250"
    data-aos-duration="1000">
          <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" > {{ $t('Recreation')}}</div>
          <ul class=" list-style px-2">
          <li>
            <img class="list-icon"  src="@/assets/list-icon.svg" alt="img" /> {{ $t('Recreation1')}}
          </li>
           <li>
             <img class="list-icon"  src="@/assets/list-icon.svg" alt="img" /> {{ $t('Recreation2')}}
           
          </li>
           <li>
              <img class="list-icon"  src="@/assets/list-icon.svg" alt="img" /> {{ $t('Recreation3')}}
         
          </li>
           <li>
              <img class="list-icon"  src="@/assets/list-icon.svg" alt="img" /> {{ $t('Recreation4')}}
           
          </li>
          </ul>

        </v-col>
        
        </v-row>
        </section>
         <v-row class="align-center sectionPadding">
        <v-col md="5" cols="12" data-aos="slide-right"  data-aos-easing="ease-in"

    data-aos-delay="250"
    data-aos-duration="1000">
                    <img class="about-img"  src="@/assets/about3.png" alt="img" />

        </v-col>
          <v-col md="7" cols="12" data-aos="slide-left"  data-aos-easing="ease-in"

    data-aos-delay="250"
    data-aos-duration="1000">
          <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" >{{ $t('Residence')}}</div>
          <ul  class=" list-style px-2">
          <li>
            <img class="list-icon"  src="@/assets/list-icon.svg" alt="img" /> {{ $t('Residence1')}}
           
          </li>
           <li>
            <img class="list-icon"  src="@/assets/list-icon.svg" alt="img" />  {{ $t('Residence2')}}
        
          </li>
           <li>
            <img class="list-icon"  src="@/assets/list-icon.svg" alt="img" />  {{ $t('Residence3')}}
         
          </li>
           <li>
            <img class="list-icon"  src="@/assets/list-icon.svg" alt="img" />  {{ $t('Residence4')}}
     
          </li>
          </ul>

        </v-col>
        </v-row>
      </v-container>
    </section>

</template>
<script>
export default {
  name:'about',

}

</script>
<style lang="scss" scoped>
.sectionPattern{
    background: url('~@/assets/app-pattern.svg'),;
    background-position: top left;
    background-repeat: no-repeat;
}
.sectionPadding{
    padding: 2% 4% !important;

}
.sectionPattern2{
  position: relative;
  width: 100%;
}
.pattern{
      background: url('~@/assets/pattern.png');
    background-position: left;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    opacity: 0.5;
    height: 100%;
}
.about-img{
    width: 100%;
}
.list-style{
  list-style: none;
}
.list-icon{
  width: 10px;
}
  .ico-img01{
    height: inherit;
    padding: 5px;
  }
  .circle-txt{
    color: #a5a5a5;
    font-size:11px;
    padding-top: 5px;
    left: 38%;
    position:absolute;
    display:none;
  }
    .circle-subtxt{
    color: #fff;
    font-size:12px;
    padding-top: 20px;
    left: 38%;
    position:absolute;
     display:none;

  }
@media only screen and (min-width: 769px) {
    .grey-circle{
    width: 35px;
    height: 35px;
    position: absolute;
    left: 8%;
   top: 30%;
    border-radius: 50%;
    background: #020808;
    opacity: 0.46;
    cursor: pointer;
  }
    .grey-circle:hover{
        left: 7%;
   top: 29%;
          width: 190px;
    height: 75px;
    background: #020808;
    opacity: 0.7;
    
    .circle-subtxt,.circle-txt{
      display:block;
    }
  }
    .grey-circle2{
      width: 35px;
    height: 35px;
    position: absolute;
    left: 23%;
   top: 30%;
    border-radius: 50%;
    background: #020808;
    opacity: 0.46;
    cursor: pointer;
  }
    .grey-circle2:hover{
     left: 22%;
   top: 30%;
          width: 190px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
  .grey-circle3{
       width: 35px;
    height: 35px;
    position: absolute;
    left: 36.5%;
   top: 30%;
    border-radius: 50%;
    background: #020808;
    opacity: 0.46;
    cursor: pointer;
   
  }
    .grey-circle3:hover{
     left: 35.5%;
   top: 30%;
          width: 190px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
  .grey-circle4{
        width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
   top: 30%;
    border-radius: 50%;
    background: #020808;
    opacity: 0.46;
    cursor: pointer;
  }
    .grey-circle4:hover{
    left: 49%;
   top: 30%;
          width: 190px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
   .grey-circle5{
       width: 35px;
    height: 35px;
    position: absolute;
    left: 63.5%;
   top: 30%;
    border-radius: 50%;
    background: #020808;
    opacity: 0.46;
    cursor: pointer;
  }
    .grey-circle5:hover{
   left: 62.5%;
   top: 30%;
          width: 190px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
   .grey-circle6{
        width: 35px;
    height: 35px;
    position: absolute;
    right: 18%;
   top: 30%;
    border-radius: 50%;
    background: #020808;
    opacity: 0.46;
    cursor: pointer;
  }
    .grey-circle6:hover{
  left: 79%;
   top: 30%;
          width: 190px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
  html:lang(ar){
  .TxtAr{
      transform: scaleX(-1);
      font-family: "gess-Light";
    }
      .grey-circle:hover{
        left: 1%;
   top: 29%;
          width: 190px;
    height: 75px;
    background: #020808;
    opacity: 0.7;
    
    .circle-subtxt,.circle-txt{
      display:block;
    }
  }
      .grey-circle2:hover{
     left: 13%;
   top: 30%;
          width: 190px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
     .grey-circle3:hover{
     left: 26.5%;
   top: 30%;
          width: 190px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
      .grey-circle4:hover{
    left: 40%;
   top: 30%;
          width: 190px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
   .grey-circle5:hover{
   left: 53%;
   top: 30%;
          width: 190px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  } 
}
 }
  .right-txt{
  right: 38% !important;
  left: unset !important;
  padding-left: 30px;
}
.row-2{
  flex-direction: row-reverse;
}
@media only screen and (max-width: 768px) {
.ico-img01{
    height: inherit;
    padding: 5px;
  }
  .circle-txt{
    color: #a5a5a5;
    font-size:11px;
    padding-top: 5px;
    left: 38%;
    position:absolute;
    display:none;
  }
    .circle-subtxt{
    color: #fff;
    font-size:12px;
    padding-top: 20px;
    left: 38%;
    position:absolute;
     display:none;

  }
  .grey-circle{
    width: 35px;
    height: 35px;
    position: absolute;
    left: 31%;
    top: 8%;
    border-radius: 50%;
    background: #020808;
    opacity: 0.46;
  }
    .grey-circle:hover{
     left: 30%;
    top: 8%;
    width: 210px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
    .circle-subtxt,.circle-txt{
      display:block;
    }
  }
    .grey-circle2{
    width: 35px;
    height: 35px;
    position: absolute;
    left: 40%;
    top: 18%;
    border-radius: 50%;
    background: #020808;
    opacity: 0.46;
  }
    .grey-circle2:hover{
    left: 39%;
    top: 18%;
    width: 210px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
  .grey-circle3{
    width: 35px;
    height: 35px;
    position: absolute;
    left: 40%;
    top: 32%;
    border-radius: 50%;
    background: #020808;
    opacity: 0.46;
   
  }
    .grey-circle3:hover{
    left: 39%;
    top: 32%;
    width: 210px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
  .grey-circle4{
    width: 35px;
    height: 35px;
    position: absolute;
      left: 40%;
    top: 45%;
    border-radius: 50%;
    background: #020808;
    opacity: 0.46;
  }
    .grey-circle4:hover{
     left: 39%;
    top: 45%;
    width: 210px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
   .grey-circle5{
    width: 35px;
    height: 35px;
    position: absolute;
   left: 40%;
    top: 62%;
    border-radius: 50%;
    background: #020808;
    opacity: 0.46;
  }
    .grey-circle5:hover{
     left: 39%;
    top: 62%;
    width: 210px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
   .grey-circle6{
    width: 35px;
    height: 35px;
    position: absolute;
    right: 52%;
    top: 75%;
    border-radius: 50%;
    background: #020808;
    opacity: 0.46;
  }
    .grey-circle6:hover{
     left: 46%;
    top: 73%;
    width: 210px;
    height: 75px;
    background: #020808;
    opacity: 0.70;
       .circle-subtxt,.circle-txt{
      display:block;
    }
  }
}
  html:lang(ar){
  .TxtAr{
      transform: scaleX(-1);
      font-family: "gess-Light";
    }}

</style>
